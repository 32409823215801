<template>
	<div>
		<h4 class="h4_title">{{translate('feedback')}}</h4>
		
		<van-form @submit="formSubmit" ref="formItem" :show-error-message="false">

			<van-field
				clearable
				type="text"
				v-model="formItem.name"
				name="name"
				:label="translate('title')"
				:placeholder="translate('please_enter_a_feedback_title')"
				:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_enter_a_feedback_title') }]"
			/>

			<van-field
				clearable
				type="textarea"
				v-model="formItem.description"
				name="description"
				:label="translate('detailed_description')"
				autosize
				rows="6"
				:placeholder="translate('please_enter_a_description')"
				:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_enter_a_description') }]"
			/>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">{{translate('feedback_submit')}}</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	export default {
		name: 'feedback_index',
		data() {
			return {				
				formItem: {
					token: "",
					name: "",
					description: "",
				},
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
			}
		},
		methods: {
			formSubmit() {
								
				this.$toast.loading({
					message: this.translate('feedback_please_wait'),
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'feedback/insert', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {

						this.formItem.name = ""
						this.formItem.description = ""
				
						this.$toast.success({
							message:this.translate('feedback_successfully')
						})
						
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
		},
	}
</script>
